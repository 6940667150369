import React, { Suspense, useEffect } from "react";
// import { Provider } from "mobx-react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import './App.css';

import 'bootstrap/dist/css/bootstrap.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles/custom-styles.css';

const Main = React.lazy(() => import("./views/Main"));

const version = '0.1.0';

document.body.style = 'background:white;'

function App() {
  
  useEffect(() => {
    console.log(`Version: ${version}`);
  }, [])

  return (
    <React.Fragment>
      <Suspense fallback={<div></div>}>
        <BrowserRouter>
          <React.Fragment>
            <Routes>
              <Route exact path={`/:region/main/:brochureId`} name="brochure" element={<Main />} />
            </Routes>
          </React.Fragment>
        </BrowserRouter>
      </Suspense>
    </React.Fragment>
  );
}

export default App;
